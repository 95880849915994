import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { propertySearchList } from "../../redux/search/search.actions";

export function PropertySearchListApiCall(
  pageNo,
  minAreaId,
  maxAreaId,
  typeId,
  categoryId,
  searchId,
  bhkId,
  priceId,
  cityId,
  localityId,
  bankInsitute
) {
  return (dispatch) => {
    dispatch(propertySearchList(null, true));
    console.log("Line No 21 bankInsitute", bankInsitute);
    console.log("Line No 21 minAreaId", minAreaId);
    console.log("Line No 21 typeId", typeId);

    const url = `${URL_CONSTANTS.PROPERTY_SEARCH_LIST}${
      typeId ? `type_id=${typeId}` : ""
    }${categoryId ? `&category_id=${categoryId}` : ""}
      ${searchId ? `&search_uniqueid=${searchId}` : ""}${
      pageNo ? `&page_number=${pageNo}` : ""
    }${bhkId ? `&bhk_id=${bhkId}` : ""}
      ${priceId ? `&price_id=${priceId}` : ""}${
      minAreaId ? `&minimum_area_id=${minAreaId}` : ""
    }${maxAreaId ? `&maximum_area_id=${maxAreaId}` : ""}
      ${cityId ? `&city_id=${cityId}` : ""}${
      localityId ? `&location_id=${localityId}` : ""
    }${bankInsitute ? `&institution_id=${bankInsitute.institution_id}` : ""}`;

    console.log(url);

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(propertySearchList(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(propertySearchList(null, false));
      });
  };
}
