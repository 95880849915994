const PropertyActionTypes = {
  PROPERTY_DETAILS: "PROPERTY_DETAILS",
  LIVE_AUCTION: "LIVE_AUCTION",
  UPCOMING_AUCTION: "UPCOMING_AUCTION",
  PROPERTY_SUMMARY: "PROPERTY_SUMMARY",
  OTHER_SALES_PROPERTIES: "OTHER_SALES_PROPERTIES",
  PROPERTY_CATEGORY_DETAILS: "PROPERTY_CATEGORY_DETAILS",
  ALL_UNITS: "ALL_UNITS"
};

export default PropertyActionTypes;
