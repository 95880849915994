import { getRequest } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { topLocalities } from "../../redux/search/search.actions";

export function TopLocalitiesApiCall(searchWord, cityId) {
  return (dispatch) => {
    dispatch(topLocalities(null, true));

    let url = '';
    if(cityId?.trim('')?.length > 0) {
      url = `${URL_CONSTANTS.TOP_LOCALITIES}${searchWord}&city_id=${cityId}`;
    } else {
      url = `${URL_CONSTANTS.TOP_LOCALITIES}${searchWord}`;
    }

    getRequest(url)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(topLocalities(res.data.data, false));
        }
      })
      .catch((error) => {
        dispatch(topLocalities(null, false));
      });
  };
}
