import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropertyCardDetailed from "../../components/Cards/PropertyCardDetailed.component";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import MiniAdvertisement from "../../components/Ads/MiniAdvertisement.component";
import Loader from "../../components/Loader/Loader.component";
import { LiveAuctionApiCall } from "../../apiCalls/Property/LiveAuctionApiCall";
import { UpcomingAuctionApiCall } from "../../apiCalls/Property/UpcomingAuctionApiCall";
import { BankAuctionPropertiesApiCall } from "../../apiCalls/User/BankAuctionPropertiesApiCall";
import { UserAdDetailsApiCall } from "../../apiCalls/User/UserAdDetailsApiCall";
import {
  getLiveAuction,
  getUpcomingAuction,
} from "../../redux/property/property.selector";
import {
  bankAuctionProperties,
  userAdDetailsState,
  showInterestState,
} from "../../redux/user/user.selector";
import NoDataIMG from "../../assets/img/no-data2.jpg";
import "./BankAuction.styles.scss";
import { Box } from "@mui/material";
import IconBtn from "../../NewDev/core/IconBtn";
import TuneIcon from "@mui/icons-material/Tune";
import Modal from "../../NewDev/core/Model";
import NewSearchData from "../../NewDev/components/NewSearchData";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";
import useModalStore from "../../NewDev/Store/model";
import axios from "axios";
import { SearchContext } from "../../App.js";
import SearchData from "../../NewDev/components/SearchData";
import useSearchActiveStore from "../../NewDev/Store/SearchActive";
import { getCategoryByPropertyType } from "../../utils/CustomFunctions";

const BankAuction = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const liveAuction = useSelector(getLiveAuction);
  const upcomingAuction = useSelector(getUpcomingAuction);
  const bankAuction = useSelector(bankAuctionProperties);
  const adDetails = useSelector(userAdDetailsState);
  const [infiniteProperties, setInfiniteProperties] = useState([]);
  const [pageNumber, setPagenumber] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const showInterest = useSelector(showInterestState);

  const [filterList, setFilterList] = useState([]);

  const [allproperty, setAllproperty] = useState(false);

  const [loader, setLoader] = useState(false);

  const searchData = useContext(SearchContext).searchData;

  const { active, setActive, setDeactive } = useSearchActiveStore((state) => ({
    active: state.active,
    setActive: state.setActive,
    setDeactive: state.setDeactive,
  }));

  const observer = useRef();

  const Dimensions = useWindowDimensions();
  const { open, handleOpen, handleClose } = useModalStore((state) => ({
    open: state.open,
    handleOpen: state.handleOpen,
    handleClose: state.handleClose,
  }));

  useEffect(() => {
    dispatch(UserAdDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastElementRef = useCallback(
    (node) => {
      if (bankAuction.loader || upcomingAuction.loader || liveAuction.loader)
        return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (pageNumber < totalPages) {
            setPagenumber((old) => old + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      bankAuction.loader,
      liveAuction.loader,
      pageNumber,
      totalPages,
      upcomingAuction.loader,
    ]
  );

  useEffect(() => {
    if (pageNumber === 1000) {
      if (
        location?.state?.title === "Bank Auction Properties" &&
        bankAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties(bankAuction.data?.data);
        settotalPages(bankAuction.data?.total_page_count);
      } else if (
        location?.state?.title === "Upcoming Auction Properties" &&
        upcomingAuction?.data?.data?.length > 0
      ) {
        settotalPages(upcomingAuction.data?.total_page_count);
        setInfiniteProperties(upcomingAuction.data?.data);
      } else if (
        location?.state?.title === "Live Auction Properties" &&
        liveAuction?.data?.data?.length > 0
      ) {
        settotalPages(liveAuction.data?.total_page_count);
        setInfiniteProperties(liveAuction.data?.data);
      }
    } else {
      if (
        location?.state?.title === "Bank Auction Properties" &&
        bankAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties((old) => [...old, ...bankAuction.data?.data]);
      } else if (
        location?.state?.title === "Upcoming Auction Properties" &&
        upcomingAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties((old) => [...old, ...upcomingAuction.data?.data]);
      } else if (
        location?.state?.title === "Live Auction Properties" &&
        liveAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties((old) => [...old, ...liveAuction.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveAuction.data, upcomingAuction.data, bankAuction.data]);

  useEffect(() => {
    if (location?.state?.title === "Bank Auction Properties") {
      dispatch(BankAuctionPropertiesApiCall(pageNumber));
    } else if (location?.state?.title === "Upcoming Auction Properties") {
      dispatch(UpcomingAuctionApiCall(pageNumber, 10));
    } else if (location?.state?.title === "Live Auction Properties") {
      dispatch(LiveAuctionApiCall(pageNumber, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setPagenumber(1);
    if (location?.state?.title === "Bank Auction Properties") {
      dispatch(BankAuctionPropertiesApiCall(1));
    }
    if (location?.state?.title === "Live Auction Properties") {
      setLoader(true);
      const response = axios.get(
        "https://sarfaesiauctions.com/v1/api/property/auction/live?page_number=1&page_size=1000"
      );
      response
        .then((res) => {
          setLiveList(res.data.data.data);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          alert("error");
        });
    } else if (location?.state?.title === "Upcoming Auction Properties") {
      setLoader(true);
      const response = axios.get(
        "https://sarfaesiauctions.com/v1/api/property/auction/upcoming?page_number=1&page_size=1500"
      );
      response
        .then((res) => {
          setLoader(false);
          setLiveList(res.data.data.data);
        })
        .catch((error) => {
          alert("error");
          setLoader(false);
        });
    }
  }, [location?.state?.title]);

  const [liveList, setLiveList] = useState([]);

  const [fList, setFlist] = useState([]);

  useEffect(() => {
    if (location?.state?.title === "Live Auction Properties") {
      setLoader(true);
      const response = axios.get(
        "https://sarfaesiauctions.com/v1/api/property/auction/live?page_number=1&page_size=1000"
      );
      response
        .then((res) => {
          setLiveList(res.data.data.data);

          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          alert("error");
        });
    } else if (location?.state?.title === "Upcoming Auction Properties") {
      setLoader(true);
      const response = axios.get(
        "https://sarfaesiauctions.com/v1/api/property/auction/upcoming?page_number=1&page_size=1500"
      );
      response
        .then((res) => {
          setLoader(false);
          setLiveList(res.data.data.data);
        })
        .catch((error) => {
          alert("error");
          setLoader(false);
        });
    } else {
      const res1 = axios.get(
        "https://sarfaesiauctions.com/v1/api/property/auction/live?page_number=1&page_size=1000"
      );
      const res2 = axios.get(
        "https://sarfaesiauctions.com/v1/api/property/auction/upcoming?page_number=1&page_size=1000"
      );
      Promise.all([res1, res2])
        .then((res) => {
          console.log(res);
          const array = [...res[0].data.data.data, ...res[1].data.data.data];
          setFilterList(array);
        })
        .catch((error) => {});
    }
  }, [location?.state?.title]);

  useEffect(() => {
    if (location?.state?.title === "Bank Auction Properties") {
      dispatch(BankAuctionPropertiesApiCall(pageNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setPagenumber(1);
    if (location?.state?.title === "Bank Auction Properties") {
      setAllproperty(true);
      dispatch(BankAuctionPropertiesApiCall(1));
    } else {
      setAllproperty(false);
    }
  }, [location?.state?.title]);

  function filterFunction(properties, criteria) {
    const convertSqYardToSqFt = (areaInSqYard) => areaInSqYard * 9;
    const convertSqMeterToSqFt = (areaInSqMeter) => areaInSqMeter * 10.764;

    if (criteria.property_id) {
      return properties.filter((property) => {
        const meetsCriteria = property.property_id === criteria.property_id;
        return meetsCriteria;
      });
    } else if (
      (criteria.institution !== "" || criteria.institution !== undefined) &&
      criteria.type_name == "" &&
      criteria.subType == ""
    ) {
      console.log("True is that ");

      return properties.filter((property) => {
        const areaSqft =
          property.property_area_unit === "sq.meter"
            ? convertSqMeterToSqFt(property.property_area)
            : property.property_area_unit === "sq.yard"
            ? convertSqYardToSqFt(property.property_area)
            : property.property_area;

        const propertyPrice = parseInt(property.price.replace(/,/g, ""));
        const meetsCriteria =
          areaSqft >= criteria.min_area &&
          areaSqft <= criteria.max_area &&
          propertyPrice >= parseInt(criteria.min_price.replace(/,/g, "")) &&
          propertyPrice <= parseInt(criteria.max_price.replace(/,/g, "")) &&
          (criteria.institution === "" ||
            property.institution === criteria.institution) &&
          (criteria.city_name === "" ||
            property.city_name === criteria.city_name) &&
          (criteria.locality_name === "" ||
            property.locality_name === criteria.locality_name);

        return meetsCriteria;
      });
    } else if (!criteria.property_id || !criteria.subType) {
      return properties.filter((property) => {
        const areaSqft =
          property.property_area_unit === "sq.meter"
            ? convertSqMeterToSqFt(property.property_area)
            : property.property_area_unit === "sq.yard"
            ? convertSqYardToSqFt(property.property_area)
            : property.property_area;

        const propertyPrice = parseInt(property.price.replace(/,/g, ""));
        const meetsCriteria =
          areaSqft >= criteria.min_area &&
          areaSqft <= criteria.max_area &&
          propertyPrice >= parseInt(criteria.min_price.replace(/,/g, "")) &&
          propertyPrice <= parseInt(criteria.max_price.replace(/,/g, "")) &&
          (criteria.institution === "" ||
            property.institution === criteria.institution) &&
          (criteria.city_name === "" ||
            property.city_name === criteria.city_name) &&
          (criteria.locality_name === "" ||
            property.locality_name === criteria.locality_name) &&
          (criteria.type_name === "" ||
            property.type_name === criteria.type_name);

        return meetsCriteria;
      });
    } else if (criteria.subType) {
      return properties.filter((property) => {
        const areaSqft =
          property.property_area_unit === "sq.meter"
            ? convertSqMeterToSqFt(property.property_area)
            : property.property_area_unit === "sq.yard"
            ? convertSqYardToSqFt(property.property_area)
            : property.property_area;

        const propertyPrice = parseInt(property.price.replace(/,/g, ""));
        const meetsCriteria =
          areaSqft >= criteria.min_area &&
          areaSqft <= criteria.max_area &&
          propertyPrice >= parseInt(criteria.min_price.replace(/,/g, "")) &&
          propertyPrice <= parseInt(criteria.max_price.replace(/,/g, "")) &&
          (criteria.institution === "" ||
            property.institution === criteria.institution) &&
          (criteria.city_name === "" ||
            property.city_name === criteria.city_name) &&
          (criteria.locality_name === "" ||
            property.locality_name === criteria.locality_name) &&
          getCategoryByPropertyType(
            property.type_name,
            property.category_name
          ) === criteria.subType;

        return meetsCriteria;
      });
    } else {
      return properties.filter((property) => {
        const areaSqft =
          property.property_area_unit === "sq.meter"
            ? convertSqMeterToSqFt(property.property_area)
            : property.property_area_unit === "sq.yard"
            ? convertSqYardToSqFt(property.property_area)
            : property.property_area;

        const propertyPrice = parseInt(property.price.replace(/,/g, ""));
        const meetsCriteria =
          property.property_id === criteria.property_id &&
          areaSqft >= criteria.min_area &&
          areaSqft <= criteria.max_area &&
          propertyPrice >= parseInt(criteria.min_price.replace(/,/g, "")) &&
          propertyPrice <= parseInt(criteria.max_price.replace(/,/g, "")) &&
          (criteria.institution === "" ||
            property.institution === criteria.institution) &&
          (criteria.city_name === "" ||
            property.city_name === criteria.city_name) &&
          (criteria.locality_name === "" ||
            property.locality_name === criteria.locality_name) &&
          (criteria.type_name === "" ||
            property.type_name === criteria.type_name);

        return meetsCriteria;
      });
    }
  }

  useEffect(() => {
    if (pageNumber === 1) {
      if (
        location?.state?.title === "Bank Auction Properties" &&
        bankAuction?.data?.data?.length > 0
      ) {
        setAllproperty(true);
        setInfiniteProperties(bankAuction.data?.data);
        settotalPages(bankAuction.data?.total_page_count);
      } else if (
        location?.state?.title === "Upcoming Auction Properties" &&
        upcomingAuction?.data?.data?.length > 0
      ) {
        settotalPages(upcomingAuction.data?.total_page_count);
        setInfiniteProperties(upcomingAuction.data?.data);
      } else if (
        location?.state?.title === "Live Auction Properties" &&
        liveAuction?.data?.data?.length > 0
      ) {
        settotalPages(liveAuction.data?.total_page_count);
        setInfiniteProperties(liveAuction.data?.data);
      }
    } else {
      if (
        location?.state?.title === "Bank Auction Properties" &&
        bankAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties((old) => [...old, ...bankAuction.data?.data]);
      } else if (
        location?.state?.title === "Upcoming Auction Properties" &&
        upcomingAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties((old) => [...old, ...upcomingAuction.data?.data]);
      } else if (
        location?.state?.title === "Live Auction Properties" &&
        liveAuction?.data?.data?.length > 0
      ) {
        setInfiniteProperties((old) => [...old, ...liveAuction.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveAuction.data, upcomingAuction.data, bankAuction.data]);

  useEffect(() => {
    if (active) {
      handleClose();
      const minPrice = new Intl.NumberFormat("en-IN").format(
        searchData.price[0] === 0 ? 1 * 100000 : searchData.price[0] * 100000
      );
      const maxPrice = new Intl.NumberFormat("en-IN").format(
        searchData.price[1] === 0 ? 1 * 100000 : searchData.price[1] * 100000
      );

      console.log("273", searchData);

      const criteria1 = {
        property_id: searchData.propertyId,
        min_area: searchData.area[0] === 300 ? 0 : searchData.area[0],
        max_area: searchData.area[1] === 10000 ? 1000000 : searchData.area[1],
        min_price: minPrice,
        max_price: maxPrice,
        institution:
          searchData?.bankInsitute?.institution === "All Institution"
            ? ""
            : searchData?.bankInsitute?.institution || "",
        city_name: searchData?.cityData?.city_name || "",
        locality_name: searchData?.localityData?.locality_name || "",
        type_name: searchData.type == "All Types" ? "" : searchData.type || "",
        subType: searchData.subType || "",
      };

      console.log("criteria1 422", criteria1);
      console.log("criteria1 423", filterList);

      // liveList.length > 0 ? liveList : filterList,
      const list = filterFunction(
        location?.state?.title === "Bank Auction Properties"
          ? filterList
          : liveList,
        criteria1
      );
      console.log(list);
      setFlist(list);
    }
  }, [searchData]);

  useEffect(() => {
    if (active) {
      setDeactive();
    }
  }, []);

  return (
    <>
      {(location?.state?.title === "Live Auction Properties" ||
        location?.state?.title === "Upcoming Auction Properties") && (
        <div className="container-fluid bank-auction-outer">
          <Loader
            visible={showInterest.loader || bankAuction?.loader || loader}
          />
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs-outer">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">
              {location?.state?.title}
            </Typography>
          </Breadcrumbs>
          <MiniAdvertisement
            data1={adDetails?.data?.Bank_Auction_Page_Slot_1}
            data2={adDetails?.data?.Bank_Auction_Page_Slot_2}
            data3={adDetails?.data?.Bank_Auction_Page_Slot_3}
          />

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pl={{lg:5.5}}
          >
            <h2 style={{ fontWeight: "bold", color: "#000" }}>
              {location?.state?.title}
            </h2>

            <IconBtn onClick={handleOpen}>
              <TuneIcon
                sx={{
                  position: "relative",
                  right: 0,
                  left: "auto",
                  color: "#000 !important",
                  fontSize: "30px",
                }}
                color="#000"
              />
            </IconBtn>
          </Box>

          {liveList?.length === 0 ? (
            <div className="results-empty container">
              <img src={NoDataIMG} alt=" " />
              <h1>No Properties Found...</h1>
            </div>
          ) : (
            <div className="row cards-wrapper pl-lg-5">
              <div className="col-12 col-lg-10">
                <div className="row">
                  {active ? (
                    fList.length > 0 ? (
                      fList?.map((data, index) => {
                        return (
                          <>
                            <div
                              className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                              key={data?.property_id}
                              ref={lastElementRef}
                            >
                              <PropertyCardDetailed data={data} />
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="results-empty container">
                        <img src={NoDataIMG} alt=" " />
                        <h1>No Properties Found...</h1>
                      </div>
                    )
                  ) : (
                    liveList?.map((data, index) => {
                      return (
                        <>
                          <div
                            className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                            key={data?.property_id}
                            ref={lastElementRef}
                          >
                            <PropertyCardDetailed data={data} />
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {location?.state?.title === "Bank Auction Properties" && (
        <div className="container-fluid bank-auction-outer">
          <Loader visible={showInterest.loader || bankAuction?.loader} />
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs-outer">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">
              {location?.state?.title}
            </Typography>
          </Breadcrumbs>
          <MiniAdvertisement
            data1={adDetails?.data?.Bank_Auction_Page_Slot_1}
            data2={adDetails?.data?.Bank_Auction_Page_Slot_2}
            data3={adDetails?.data?.Bank_Auction_Page_Slot_3}
          />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={{lg:2}}
          >
            <h2 style={{ fontWeight: "bold", color: "#000" }}>
              {location?.state?.title}
            </h2>

            <IconBtn onClick={handleOpen}>
              <TuneIcon
                sx={{
                  position: "relative",
                  right: 0,
                  left: "auto",
                  color: "#000 !important",
                  fontSize: "30px",
                }}
                color="#000"
              />
            </IconBtn>
          </Box>
          {infiniteProperties?.length === 0 ? (
            <div className="results-empty container">
              <img src={NoDataIMG} alt=" " />
              <h1>No Properties Found...</h1>
            </div>
          ) : (
            <div className="row cards-wrapper pl-lg-5" >
              <div className="col-12 col-lg-10">
                <div className="row">
                  {active ? (
                    fList.length > 0 ? (
                      fList?.map((data, index) => {
                        return (
                          <>
                            <div
                              className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                              key={data?.property_id}
                              ref={lastElementRef}
                            >
                              <PropertyCardDetailed data={data} />
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="results-empty container">
                        <img src={NoDataIMG} alt=" " />
                        <h1>No Properties Found...</h1>
                      </div>
                    )
                  ) : (
                    liveList?.map((data, index) => {
                      return (
                        <>
                          <div
                            className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                            key={data?.property_id}
                            ref={lastElementRef}
                          >
                            <PropertyCardDetailed data={data} />
                          </div>
                        </>
                      );
                    })
                  )}

                  {!active &&
                    infiniteProperties?.map((property, index) =>
                      infiniteProperties.length === index + 1 ? (
                        <div
                          className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                          key={property?.property_id}
                          ref={lastElementRef}
                        >
                          <PropertyCardDetailed data={property} />
                        </div>
                      ) : (
                        <div
                          className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                          key={property?.property_id}
                        >
                          <PropertyCardDetailed data={property} />
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="col-2 empty-class"></div>
            </div>
          )}
        </div>
      )}

      <Modal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        width={"80%"}
      >
        {" "}
        <NewSearchData />{" "}
      </Modal>
    </>
  );
};

export default BankAuction;
