import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import { useGoogleLogin } from '@react-oauth/google';
import Countdown from "react-countdown";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SignupBanner from "../../../assets/img/login1.jpg";
import OtpImg from "../../../assets/img/otp.jpg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Loader from '../../../components/Loader/Loader.component';
import { emailValidation, normalFieldValidation, phoneNumberValidation } from '../../../utils/validations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AddEndUserApiCall } from '../../../apiCalls/User/AddEndUserApiCall';
import { VerifyOTPApiCall } from '../../../apiCalls/User/VerifyOTPApiCall';
import { TopTenCitiesApiCall } from '../../../apiCalls/Search/TopTenCitiesApiCall';
import { TopLocalitiesApiCall } from '../../../apiCalls/Search/TopLocalitiesApiCall';
import { PropertyCategoryDetailsApiCall } from '../../../apiCalls/Property/PropertyCategoryDetailsApiCall';
import { GoogleSignInApiCall } from '../../../apiCalls/User/GoogleSignInApiCall';
import { ResendOTPApiCall } from '../../../apiCalls/User/ResendOTPApiCall';
import { propertyCategoryDetails } from '../../../redux/property/property.selector';
import { getTopLocalities, getTopTenCities } from '../../../redux/search/search.selector';
import { verifyOTPState, addEndUserState } from '../../../redux/user/user.selector';
import { verifyOTP } from "../../../redux/user/user.actions";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { GOOGLE_SIGN_IN } from '../../../constants/iconConstants';
import "../auth.scss";

const useStyles = makeStyles((theme) => ({
  formControlCustom: {
    maxWidth: 500,
  },
  button: {
    height: 40,
    width: '100%',
    maxWidth: 500
  },
  wrapper: {
    position: 'relative',
  },
  fabProgress: {
    color: '#fff',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Signup = () => {

  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const topCities = useSelector(getTopTenCities);
  const topLocalities = useSelector(getTopLocalities);
  const categoryDetails = useSelector(propertyCategoryDetails);
  const verifyOtpMsg = useSelector(verifyOTPState);
  const userCreated = useSelector(addEndUserState);
  const [fieldValues, setFieldValues] = useState({
    name: '',
    locality: '',
    city: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    propertyType: 'Residential',
    budget: '10-25 Lakhs',
    userType: '',
    otp: null
  });
  const [nameErr, setNameErr] = useState(null);
  const [EmailValidationMessage, setEmailValidationMessage] = useState(null);
  const [PasswordValidationMessage, setPasswordValidationMessage] = useState(null);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);
  const [phoneErr, setPhoneErr] = useState(null);
  const [privacyPolicyAgree, setPrivacyPolicyAgree] = useState(false);
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.label,
  });
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [isVisibleOtpPage, setOtpPage] = useState(false);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    dispatch(PropertyCategoryDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userCreated.data === 'Success') {
      setOtpPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreated.data]);

  const toastView = (message, appearance) => {
    addToast(message, { appearance: appearance, autoDismiss: true });
  }

  const validation = (event, text) => {
    setFieldValues({
      ...fieldValues,
      [text]: event.target.value
    });
    let value = event.target.value;
    if (text === 'name') {
      setNameErr(normalFieldValidation(value, 'Name'));
    } else if (text === 'email') {
      setEmailValidationMessage(emailValidation(value));
    } else if (text === 'password') {
      if (value.length >= 8) {
        setPasswordValidationMessage(null);
      } else {
        setPasswordValidationMessage("Min. of 8 characters required.");
      }
    } else if (text === 'confirmPassword') {
      if (value === fieldValues.password) {
        setConfirmPasswordErr(null);
      } else {
        setConfirmPasswordErr("Password doesn't match");
      }
    } else if (text === 'phone') {
      setPhoneErr(phoneNumberValidation(value));
    } else if (text === 'otp') {
      setOtp(event.target.value);
    }
  }

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const timer = useRef();

  useEffect(() => {
    dispatch(TopTenCitiesApiCall(fieldValues.city));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues.city]);

  useEffect(() => {
    if (selectedCity) {
      dispatch(TopLocalitiesApiCall(fieldValues.locality, selectedCity?.city_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues.locality]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyOtp = () => {
    if (!loading) {
      setLoading(true);
      dispatch(VerifyOTPApiCall(fieldValues.phone, otp, toastView));
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  };

  const resendOtp = () => {
    dispatch(ResendOTPApiCall(fieldValues?.phone, toastView));
  };

  const ResendOtpSection = () => <p className="resend-otp-label">Don't receive OTP? <span onClick={resendOtp} >Resend</span></p>;

  const registerUser = (e) => {
    e.preventDefault();
    let pId = '';
    if (categoryDetails.data?.types?.length > 0) {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === fieldValues.propertyType) {
          pId = info[i]?.type_id;
        }
      }
    }
    let data = {
      name: fieldValues.name,
      email: fieldValues.email,
      phone: fieldValues.phone,
      city_id: selectedCity?.city_id ?? '',
      locality_id: selectedLocality?.locality_id ?? '',
      property_type_id: pId,
      password: fieldValues.password,
      budget: fieldValues.budget,
      user_type: fieldValues.userType
    };
    if (!selectedLocality?.locality_id) {
      data = {
        ...data,
        locality_name: fieldValues?.locality
      };
    }
    dispatch(AddEndUserApiCall(data, toastView));
  };

  const handleLogin = () => {
    setOtpPage(false);
    dispatch(verifyOTP(null, false));
  }

  // Google sign in
  const googleSignIn = useGoogleLogin({
    onError: (error) => {
      
    },
    onSuccess: (codeResponse) => {
      dispatch(GoogleSignInApiCall(codeResponse.code, toastView));
    },
    flow: 'auth-code'
  });

  return (
    <div className="container-fluid signup-outer">
      <Loader visible={userCreated.loader} />
      {verifyOtpMsg?.data === 'Account Verified' ? (
        <div className="otp-success-outer">
          <div className="otp-success-img__wrapper">
            <img src={OtpImg} alt="Login Banner" />
          </div>
          <div>
            <h2>Awesome!</h2>
            <p>Your phone number has been successfully verified. Please Login</p>

            <Link to='/login'>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Login
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-5">
            {isVisibleOtpPage === false ? (
              <img src={SignupBanner} alt="Login Banner" className={isVisibleOtpPage ? '' : 'login-banner'} />
            ) : (
              <img src={OtpImg} alt="Login Banner" className={isVisibleOtpPage ? '' : 'login-banner'} />
            )}
          </div>
          {isVisibleOtpPage === false ? (
            <div className="col-md-7">
              <h3>Register</h3>
              <p className="mb-3">
                Already have an account ?
                <Link
                  to={{
                    pathname: "/login",
                  }}
                >
                  Log in
                </Link>
              </p>

              {/* Google sign in */}
              <div className='google-sign-in-wrap' onClick={googleSignIn}>
                <img src={GOOGLE_SIGN_IN.default} alt='' />
              </div>

              <p className='or-txt'>OR</p>

              <form noValidate autoComplete="off" onSubmit={registerUser}>
                <div className="container-fluid" style={{ padding: 0 }}>
                  <div className="row">
                    <div className="col-12">
                      <FormControl className="mb-1">
                        <FormLabel>I am a</FormLabel>
                        <RadioGroup aria-label="I am a" className="flex-row" value={fieldValues.userType} onChange={(event) => validation(event, 'userType')}>
                          <FormControlLabel value="Buyer" control={<Radio />} label="Buyer" />
                          <FormControlLabel value="Seller" control={<Radio />} label="Seller" />
                          <FormControlLabel value="Investor" control={<Radio />} label="Investor" />
                          <FormControlLabel value="Agent" control={<Radio />} label="Agent" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        required
                        fullWidth
                        autoFocus
                        id="outlined-basic"
                        label="Name"
                        type="text"
                        variant="outlined"
                        value={fieldValues.name}
                        error={nameErr}
                        helperText={nameErr}
                        className={classes.formControlCustom}
                        onChange={(event) => validation(event, 'name')}
                        size="small"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        required
                        fullWidth
                        value={fieldValues.email}
                        id="outlined-basic"
                        label="E-mail"
                        type="email"
                        variant="outlined"
                        error={EmailValidationMessage}
                        helperText={EmailValidationMessage}
                        className={classes.formControlCustom}
                        onChange={(event) => validation(event, 'email')}
                        size="small"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={fieldValues.password}
                        type={values.showPassword ? 'text' : 'password'}
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        error={PasswordValidationMessage}
                        helperText={PasswordValidationMessage}
                        onChange={(event) => validation(event, 'password')}
                        className={classes.formControlCustom}
                        size="small"
                        InputProps={{
                          endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={fieldValues.confirmPassword}
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        id="outlined-basic"
                        label="Confirm Password"
                        variant="outlined"
                        error={confirmPasswordErr}
                        helperText={confirmPasswordErr}
                        onChange={(event) => validation(event, 'confirmPassword')}
                        className={classes.formControlCustom}
                        size="small"
                        InputProps={{
                          endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Autocomplete
                        id="city-dropdown"
                        freeSolo
                        options={topCities.data ?? []}
                        disableClearable
                        noOptionsText='No cities'
                        getOptionLabel={(option) => option.city_name}
                        style={{ width: '100%' }}
                        onChange={(event, newValue) => {
                          setSelectedCity(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="City"
                            className={`${classes.formControlCustom} city-label`}
                            variant="outlined"
                            size="small"
                            onChange={(e) => setFieldValues({
                              ...fieldValues,
                              city: e.target.value
                            })}
                            helperText="City at which you are looking to buy / sell property"
                          />
                        )}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Autocomplete
                        id="locality-dropdown"
                        freeSolo
                        options={topLocalities.data ?? []}
                        disableClearable
                        noOptionsText='No localities'
                        getOptionLabel={(option) => option.locality_name}
                        style={{ width: '100%' }}
                        onChange={(event, newValue) => {
                          setSelectedLocality(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Locality"
                            className={`${classes.formControlCustom} city-label`}
                            variant="outlined"
                            size="small"
                            onChange={(e) => setFieldValues({
                              ...fieldValues,
                              locality: e.target.value
                            })}
                            helperText="Locality at which you are looking to buy / sell property"
                          />
                        )}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        value={fieldValues.phone}
                        type="number"
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        error={phoneErr}
                        helperText={phoneErr}
                        onChange={(event) => validation(event, 'phone')}
                        className={classes.formControlCustom}
                        size="small"
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        select
                        value={fieldValues.propertyType}
                        type="text"
                        id="outlined-basic"
                        label="Property Type"
                        variant="outlined"
                        SelectProps={{
                          native: true,
                        }}
                        onChange={(event) => validation(event, 'propertyType')}
                        className={classes.formControlCustom}
                        size="small"
                      >
                        <option key="Residential" value="Residential">Residential</option>
                        <option key="Commercial" value="Commercial">Commercial</option>
                        <option key="Industrial" value="Industrial">Industrial</option>
                        <option key="Agricultural" value="Agricultural">Agricultural</option>
                      </TextField>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <TextField
                        fullWidth
                        required
                        select
                        value={fieldValues.budget}
                        type="text"
                        id="outlined-basic"
                        label="Budget"
                        variant="outlined"
                        SelectProps={{
                          native: true,
                        }}
                        onChange={(event) => validation(event, 'budget')}
                        className={classes.formControlCustom}
                        size="small"
                      >
                        <option key="10 - 25 Lakhs" value="10 - 25 Lakhs">10 - 25 Lakhs</option>
                        <option key="25 - 40 Lakhs" value="25 - 40 Lakhs">25 - 40 Lakhs</option>
                        <option key="40 - 80 Lakhs" value="40 - 80 Lakhs">40 - 80 Lakhs</option>
                        <option key="Above 80 Lakhs" value="Above 80 Lakhs">Above 80 Lakhs</option>
                        <option key="Others" value="Others">Others</option>
                      </TextField>
                    </div>
                    <div className="col-12">
                      <FormControlLabel
                        className="privacy-agree"
                        control={
                          <Checkbox
                            checked={privacyPolicyAgree}
                            onChange={(e) => setPrivacyPolicyAgree(e.target.checked)}
                            name="agree"
                          />
                        }
                        label={
                          <p>
                            I agree to the Sarfaesi Auctions
                            <Link to='privacy-policy'>Privacy Policy</Link>
                          </p>
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={!privacyPolicyAgree}
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : verifyOtpMsg?.data === 'Account Verified' ? (
            <div className="col-md-7 otp-main-outer">
              <h2>Awesome!</h2>
              <p>Your phone number has been successfully verified. Please Login</p>

              <Link to='/login'>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </Link>
            </div>
          ) : (
            <div className="col-md-7 otp-main-outer">
              <h2>Please verify your Mobile number</h2>
              <p>We have just sent an OTP to registered number</p>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <TextField
                    fullWidth
                    required
                    value={fieldValues.otp}
                    type="number"
                    id="outlined-basic"
                    label="OTP"
                    variant="outlined"
                    helperText={phoneErr}
                    onChange={(event) => validation(event, 'otp')}
                    className={classes.formControlCustom}
                    size="small"
                  />
                </div>
              </div>
              <p>
                <Countdown zeroPadTime='2' date={Date.now() + 300000}>
                  <ResendOtpSection />
                </Countdown>
              </p>
              <div className={classes.wrapper}>
                <div className="col-xl-6 col-lg-6 col-md-12 pl-0">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={loading}
                    onClick={verifyOtp}
                  >
                    Verify
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </div>
          )}

        </div>
      )}
    </div>
  );
};

export default Signup;
