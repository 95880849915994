import { createSelector } from "reselect";

const selectInstitution = (state) => state.institution;

export const searchInstitution = createSelector(
  [selectInstitution],
  (institution) => institution.searchInstitution
);

export const addInstitution = createSelector(
  [selectInstitution],
  (institution) => institution.addInstitution
);

export const editInstitution = createSelector(
  [selectInstitution],
  (institution) => institution.editInstitution
);

export const allInstitution = createSelector(
  [selectInstitution],
  (institution) => institution.allInstitution
);