import PropertyActionTypes from "./property.types";

const initialPropertyState = {
  propertyDetails: {
    data: null,
    loader: false
  },
  liveAuction: {
    data: null,
    loader: false
  },
  upcomingAuction: {
    data: null,
    loader: false
  },
  propertySummary: {
    data: null,
    loader: false
  },
  otherSalesProperties: {
    data: null,
    loader: false
  },
  propertyCategoryDetails: {
    data: null,
    loader: false
  },
  allUnits: {
    data: null,
    loader: false
  }
};

const propertyReducer = (state = initialPropertyState, action) => {
  switch (action.type) {
    case PropertyActionTypes.PROPERTY_DETAILS:
      return {
        ...state,
        propertyDetails: {
          ...state.propertyDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PropertyActionTypes.LIVE_AUCTION:
      return {
        ...state,
        liveAuction: {
          ...state.propertyDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PropertyActionTypes.UPCOMING_AUCTION:
      return {
        ...state,
        upcomingAuction: {
          ...state.upcomingAuction,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PropertyActionTypes.PROPERTY_SUMMARY:
      return {
        ...state,
        propertySummary: {
          ...state.propertySummary,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PropertyActionTypes.OTHER_SALES_PROPERTIES:
      return {
        ...state,
        otherSalesProperties: {
          ...state.otherSalesProperties,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PropertyActionTypes.PROPERTY_CATEGORY_DETAILS:
      return {
        ...state,
        propertyCategoryDetails: {
          ...state.propertyCategoryDetails,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    case PropertyActionTypes.ALL_UNITS:
      return {
        ...state,
        allUnits: {
          ...state.allUnits,
          data: action.payload.data,
          loader: action.payload.loader
        }
      };
    default:
      return state;
  }
};

export default propertyReducer;
