import React from "react";
import AdvertisementImg from '../../assets/img/ads-default.png';
import './Advertisement.styles.scss';

export default function Advertisement({ data }) {
  const handleClick = () => {
    if(data?.url) {
      window.open(data?.url, '_blank').focus();
    }
  }

  return (
    <div className="horizontal-ads-main-outer mt-4">
      {
        data?.image ? (
          <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}${data?.image}`} onClick={handleClick} alt="Advertisement" />
        ) : (
          <img src={AdvertisementImg} alt="Ads" />
        )
      }
    </div>
  );
}