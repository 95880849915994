import { getRequestHeader } from "../apiCall";
import { URL_CONSTANTS } from "../../constants/apiConstants";
import { signedUpReport } from "../../redux/report/report.actions";

export function SignedUpReportApiCall(toastView) {
  return (dispatch) => {
    dispatch(signedUpReport(null, true));

    const url = URL_CONSTANTS.SIGNED_UP_REPORT;

    getRequestHeader(url)
      .then((res) => {
        if (res.status === 200) {
          console.log("Line No 14",res.data)
          dispatch(signedUpReport(res.data, false));
        }
      })
      .catch((error) => {
        dispatch(signedUpReport(null, false));
      });
  };
}