import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropertyCardDetailed from "../../components/Cards/PropertyCardDetailed.component";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import MiniAdvertisement from "../../components/Ads/MiniAdvertisement.component";
import Loader from "../../components/Loader/Loader.component";
import SearchFilter from "../../components/SearchFilter/SearchFilter.component";
import NoDataIMG from "../../assets/img/no-data2.jpg";
import { SearchContext } from "../../App.js";
import { UserAdDetailsApiCall } from "../../apiCalls/User/UserAdDetailsApiCall";
import { PropertyCategoryDetailsApiCall } from "../../apiCalls/Property/PropertyCategoryDetailsApiCall";
import { PropertySearchListApiCall } from "../../apiCalls/Search/PropertySearchListApiCall";
import {
  userAdDetailsState,
  showInterestState,
} from "../../redux/user/user.selector";
import { getPropertySearchList } from "../../redux/search/search.selector";
import "./SearchResults.styles.scss";
import Between from "../../NewDev/core/Between";
import { Box, Typography } from "@mui/material";
import IconBtn from "../../NewDev/core/IconBtn";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@mui/icons-material/Tune";
import SearchData from "../../NewDev/components/SearchData";
import Modal from "../../NewDev/core/Model";
import useModalStore from "../../NewDev/Store/model";

const SearchResults = () => {
  const dispatch = useDispatch();
  const searchList = useSelector(getPropertySearchList);
  const searchData = useContext(SearchContext).searchData;
  const pageNumber = useContext(SearchContext).SearchPageNumber;
  const setPagenumber = useContext(SearchContext).setSearchPageNumber;
  const [infiniteProperties, setInfiniteProperties] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const adDetails = useSelector(userAdDetailsState);
  const showInterest = useSelector(showInterestState);
  const [notSelected, setNotSelected] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [state, setState] = useState({
    type: "",
    subType: "",
    city: "",
    locality: "",
    bedsSelected: "",
    priceSelected: "",
    propertyId: "",
    minAreaSelected: "",
    maxAreaSelected: "",
    cityData: {},
    localityData: {},
    bankInsitute: null,
  });

  useEffect(() => {
    setState(searchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  const { open, handleOpen, handleClose } = useModalStore((state) => ({
    open: state.open,
    handleOpen: state.handleOpen,
    handleClose: state.handleClose,
  }));

  useEffect(() => {
    dispatch(UserAdDetailsApiCall());
    dispatch(PropertyCategoryDetailsApiCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (searchList.loader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (pageNumber < totalPages) {
            setPagenumber(pageNumber + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [searchList.loader, pageNumber, totalPages, setPagenumber]
  );

  useEffect(() => {
    if (searchList?.data !== null) {
      if (pageNumber === 1) {
        setInfiniteProperties(searchList?.data?.data);
        settotalPages(searchList?.data?.total_page_count);
      } else {
        setInfiniteProperties((old) => [...old, ...searchList?.data?.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchList.data]);

  useEffect(() => {
    if (
      searchData?.minAreaSelected?.trim("")?.length > 0 ||
      searchData?.maxAreaSelected?.trim("")?.length > 0 ||
      searchData?.subType?.trim("")?.length > 0 ||
      searchData?.propertyId?.trim("")?.length > 0 ||
      searchData?.bedsSelected?.trim("")?.length > 0 ||
      searchData?.priceSelected?.trim("")?.length > 0 ||
      searchData?.cityData?.city_name?.trim("")?.length > 0 ||
      searchData?.localityData?.locality_name?.trim("")?.length > 0 ||
      searchData?.type?.trim("")?.length > 0 ||
      searchData?.bankInsitute
    ) {
      setNotSelected(false);
      dispatch(
        PropertySearchListApiCall(
          pageNumber,
          searchData?.minAreaSelected,
          searchData?.maxAreaSelected,
          searchData?.pId,
          searchData?.subType,
          searchData?.propertyId,
          searchData?.bedsSelected,
          searchData?.priceSelected,
          searchData.city?.trim("")?.length > 0
            ? searchData?.cityData?.city_id || ""
            : "",
          searchData.locality?.trim("")?.length > 0
            ? searchData.localityData?.locality_id || ""
            : "",
          searchData?.bankInsitute && searchData.bankInsitute
        )
      );
    } else {
      setNotSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (
      searchData?.minAreaSelected?.trim("")?.length > 0 ||
      searchData?.maxAreaSelected?.trim("")?.length > 0 ||
      searchData?.subType?.trim("")?.length > 0 ||
      searchData?.propertyId?.trim("")?.length > 0 ||
      searchData?.bedsSelected?.trim("")?.length > 0 ||
      searchData?.priceSelected?.trim("")?.length > 0 ||
      searchData?.cityData?.city_name?.trim("")?.length > 0 ||
      searchData?.localityData?.locality_name?.trim("")?.length > 0 ||
      searchData?.type?.trim("")?.length > 0 ||
      searchData?.bankInsitute
    ) {
      setNotSelected(false);
    } else {
      setNotSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    console.log("Line No 136", showInterest.loader, searchList.loader);
    handleClose();
  }, [showInterest.loader, searchList.loader]);

  return (
    <>
      <div className="container-fluid search-results-outer">
        <Loader visible={showInterest.loader || searchList.loader} />
        <MiniAdvertisement
          data1={adDetails?.data?.Search_Results_Page_Slot_1}
          data2={adDetails?.data?.Search_Results_Page_Slot_2}
          data3={adDetails?.data?.Search_Results_Page_Slot_3}
        />
        {/* <Box sx={{position:"absolute",right:"0px"}}> */}

        <Breadcrumbs
          aria-label="breadcrumb"
          className="breadcrumbs-outer"
          style={{ right: "0px" }}
        >
          <Link color="inherit" href="/">
            Home
          </Link>
          <Typography color="textPrimary">Search Results</Typography>
        </Breadcrumbs>
        {/* </Box> */}

        {/* <div className="banner-main mb-4"> */}
        {/* <SearchFilter /> */}

        <Box px={2}>
          <Between>
            <Typography variant="h5" fontWeight={"700"}>
              Filter
            </Typography>
            <IconBtn onClick={handleOpen}>
              <TuneIcon
                sx={{
                  position: "relative",
                  right: 0,
                  left: "auto",
                  color: "#000 !important",
                  fontSize: "30px",
                }}
                color="#000"
              />
            </IconBtn>
          </Between>
        </Box>
        {/* </div> */}

        <div className="row">
          <div className="col-12 col-lg-10">
            <div className="row">
              {infiniteProperties?.length === 0 && (
                <div className="results-empty container">
                  <img src={NoDataIMG} alt=" " />
                  <h1>
                    {notSelected
                      ? "Please select a criteria..."
                      : "No Properties Found..."}
                  </h1>
                </div>
              )}
              {infiniteProperties?.map((item, index) =>
                infiniteProperties.length === index + 1 ? (
                  <div
                    className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3"
                    ref={lastElementRef}
                  >
                    {item?.is_bankauction == 1 ? (
                      <PropertyCardDetailed data={item} />
                    ) : (
                      <PropertyCardDetailed section="buy-sale" data={item} />
                    )}
                  </div>
                ) : (
                  <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 col-12 mt-3 mb-3">
                    {item?.is_bankauction == 1 ? (
                      <PropertyCardDetailed data={item} />
                    ) : (
                      <PropertyCardDetailed section="buy-sale" data={item} />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-1 empty-class"></div>
        </div>
      </div>

      <Modal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        width={"80%"}
      >
        <SearchData />
      </Modal>
    </>
  );
};

export default SearchResults;
