import InstitutionActionTypes from './institution.types';

const initialInstitutionState = {
  searchInstitution: null,
  addInstitution: {
    data: null,
    loader: false
  },
  editInstitution: {
    data: null,
    loader: false
  },
  allInstitution: null
}

const institutionReducer = (state = initialInstitutionState, action) => {
  switch(action.type) {
    case InstitutionActionTypes.SEARCH_INSTITUTION:
      return {
        ...state,
        searchInstitution: action.payload
      }
    case InstitutionActionTypes.ADD_INSTITUTION:
      return {
        ...state,
        addInstitution: {
          ...state.addInstitution,
          data: action.payload.data,
          loader: action.payload.loader
        }
      }
    case InstitutionActionTypes.EDIT_INSTITUTION:
      return {
        ...state,
        editInstitution: {
          ...state.editInstitution,
          data: action.payload.data,
          loader: action.payload.loader
        }
      }
    case InstitutionActionTypes.ALL_INSTITUTION:
      return {
        ...state,
        afterAllInstitution: action.payload
      }
    default:
      return state;
  }
}

export default institutionReducer;