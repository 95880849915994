import PropertyActionTypes from "./property.types";

export const propertyDetails = (data, loader) => ({
  type: PropertyActionTypes.PROPERTY_DETAILS,
  payload: {
    data,
    loader
  }
});

export const liveAuction = (data, loader) => ({
  type: PropertyActionTypes.LIVE_AUCTION,
  payload: {
    data,
    loader
  }
});

export const upcomingAuction = (data, loader) => ({
  type: PropertyActionTypes.UPCOMING_AUCTION,
  payload: {
    data,
    loader
  }
});

export const propertySummary = (data, loader) => ({
  type: PropertyActionTypes.PROPERTY_SUMMARY,
  payload: {
    data,
    loader
  }
});

export const otherSalesProperties = (data, loader) => ({
  type: PropertyActionTypes.OTHER_SALES_PROPERTIES,
  payload: {
    data,
    loader
  }
});

export const propertyCategoryDetails = (data, loader) => ({
  type: PropertyActionTypes.PROPERTY_CATEGORY_DETAILS,
  payload: {
    data,
    loader
  }
});

export const allUnits = (data, loader) => ({
  type: PropertyActionTypes.ALL_UNITS,
  payload: {
    data,
    loader
  }
});
