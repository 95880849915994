import { createSelector } from "reselect";

const selectProperty = (state) => state.property;

export const getPropertyDetails = createSelector(
  [selectProperty],
  (property) => property.propertyDetails
);

export const getLiveAuction = createSelector(
  [selectProperty],
  (property) => property.liveAuction
);

export const getUpcomingAuction = createSelector(
  [selectProperty],
  (property) => property.upcomingAuction
);

export const getPropertySummary = createSelector(
  [selectProperty],
  (property) => property.propertySummary
);

export const getOtherSalesProperties = createSelector(
  [selectProperty],
  (property) => property.otherSalesProperties
);

export const propertyCategoryDetails = createSelector(
  [selectProperty],
  (property) => property.propertyCategoryDetails
);

export const allUnitsState = createSelector(
  [selectProperty],
  (property) => property.allUnits
);